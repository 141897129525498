<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">办公系统</el-breadcrumb-item>
        <el-breadcrumb-item>流程配置</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="70px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search" @change="fetchs"></el-input>
          </el-col>
        </el-form-item>
        <!-- <el-form-item label="状态" prop="enabled" label-width="110px">
          <el-col>
            <el-select v-model="enabled" @change="fetchs">
              <el-option label="未启用" key="0" value="0" />
              <el-option label="已启用" key="1" value="1" />
            </el-select>
          </el-col>
        </el-form-item> -->

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="plus" @click="add()">
        新增
      </el-button>
      <el-button type="danger" icon="delete" @click="del()">
        删除
      </el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:toolbar="scope">
        <el-button @click="edit(scope.row)" link size="small">
          [编辑]
        </el-button>
      </template>
      <template v-slot:enabled="scope">
        {{ scope.row.enabled ? "启用" : "禁用" }}
      </template>
    </static-table>
  </el-card>
</template>
<script>
import { fetchConfiger, delConfiger } from "@/api/workflow";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "FlowConfigerIndex",
  components: { StaticTable },
  data() {
    return {
      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { type: "hidden", prop: "id", label: "id" },
          //{ prop: "category", label: "类别", sortable: true, searchable: true, width: "100", },
          { prop: "flowCode", label: "流程编号", sortable: true, searchable: true, width: "150", },
          { prop: "flowName", label: "流程名称", width: "250", sortable: true, },
          { prop: "descriptions", label: "描述", },
          { type: "template", prop: "enabled", label: "状态", width: "80", sortable: true, },
          { prop: "sortCode", label: "排序号", width: "80", sortable: true, },
          { prop: "createDate", label: "创建时间", width: "150", sortable: true, },
          { type: "toolbar", label: "操作" },
        ],
      },
      pager: {
        sidx: "sortCode", //默认的排序字段
        sord: "descending",
      },
      //multipleSelection: [], //多选的列
      currRow: null,
    };
  },
  mounted() {
    this.fetchs();
  },
  methods: {
    //this.$refs.dialog.**
    edit(row) {
      this.$router.push({ path: "flowdraw", query: { id: row.id } });
    },
    add() {
      this.$router.push("flowdraw");
    },
    del() {
      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delConfiger(that.currRow.id).then((res) => {
            if (res.code === 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "删除失败",
          });
        });
    },
    fetchs() {
      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };

      search.filters = [];
      // if (this.querys.enabled != null)
      //   search.filters.push({
      //     name: "enabled",
      //     value: this.enabled,
      //     operate: 0
      //   });
      fetchConfiger(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
  },
};
</script>
<style scoped></style>